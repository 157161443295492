<template>
  <div class="home">
    <div id="nav"><router-link to="/pics">p</router-link></div>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
};
</script>
