<template>
  <router-view />
</template>
<script>
export default {};
</script>
<style lang="scss">
html,
body {
  margin: 0px;
  overflow: hidden;
}
#app {
  max-height: 100vh;
  overflow: hidden;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0px;
  }
}
</style>
